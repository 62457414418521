<template>
  <div class="content-box-padding">
    <div class="sub-title">海洋气象监测预警台风智能定强平台</div>
    <div class="line-text" style="margin-top: 20px">
      基于先进的人工智能算法，结合气象卫星观测资料，生成台风智能定强相关产品，实现了台风灾害天气的预报预警，目前已在台风与海洋气象预报中心实时化业务运行，为海洋气象的监测、预报体系的完善打下基础。
    </div>
    <img
      src="~assets\image\business\海洋气象监测预警台风智能定强平台.png"
      alt="海洋气象监测预警台风智能定强平台"
      style="margin: 50px auto 0px auto; width: 90%"
    />
  </div>
</template>
